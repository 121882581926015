import Link from 'next/link';

import { IRepository, IRepositoryCategory } from '@/types';
import { getFlattenedCategories } from '@/lib/repositoryHelpers';
import Tag from '@/ui/Tag';
import { Row } from '@/ui/primatives';

const RepositoryCategories: React.FC<{ repository: IRepository }> = ({ repository }) => {
  const repositoryCategories: IRepositoryCategory[] = getFlattenedCategories(repository).filter(
    (category: IRepositoryCategory) => category.slug !== 'featured',
  ); // HACK: Remove after API fix;
  return (
    <Row gap={10} alignItems="center" style={{ flexWrap: 'wrap' }}>
      {repositoryCategories.map((category: IRepositoryCategory) => (
        <Link key={category.id} href={`/categories/${category.slug}`}>
          <Tag pill small key={category.id} variant="default">
            <span>{category.display_name || category.name}</span>
          </Tag>
        </Link>
      ))}
    </Row>
  );
};

export default RepositoryCategories;
