import Link from 'next/link';

import { toTimeAgo } from '@/lib/dateTime';

import * as Styled from './styled';

import { IRepository } from '@/types';
import * as Icon from '@/ui/Icon';
import Tag from '@/ui/Tag';
import { Stack, Row } from '@/ui/primatives';

import { RepositoryDataTypesWithSize } from '@/components/Repository/RepositoryDataTypes';
import RepositoryCategories from '@/components/Repository/RepositoryCategories';

const ProgressGraph: React.FC<{ repository: IRepository }> = ({ repository }) => {
  return (
    <Styled.ProgressGraph>
      <div>Sparkline</div>
    </Styled.ProgressGraph>
  );
};

const RepositoryHeader: React.FC<{ showNamespace: boolean; repository: IRepository }> = ({
  showNamespace,
  repository,
}) => (
  <Row justifyContent="space-between" className="repo-card-row">
    <Row gap={10} alignItems="center" justifyContent="flex-start">
      <h6 className="block">
        {showNamespace && (
          <>
            <Link
              className="hover-link"
              href={`/${repository.namespace}`}
              data-ph-capture-attribute-resource-type="namespace"
            >
              {repository.namespace}
            </Link>
            /
          </>
        )}
        <Link
          className="hover-link"
          href={`/${repository.namespace}/${repository.name}`}
          data-ph-capture-attribute-resource-type="respository"
        >
          {repository.name}
        </Link>
      </h6>
      <Tag pill variant="white" small border>
        {repository.is_public ? 'Public' : 'Private'}
      </Tag>
    </Row>

    <div>
      {repository.latest_commit ? (
        <Row gap={10} alignItems="center" justifyContent="flex-start">
          <Icon.Clock size="1em" />
          <div className="timestamp">{`Updated: ${toTimeAgo(repository.latest_commit.timestamp)}`}</div>
        </Row>
      ) : (
        <div className="timestamp" onClick={() => console.log('repo', repository)}>
          Empty Repository
        </div>
      )}
    </div>
  </Row>
);

const RepositoryCard: React.FC<{ repository: IRepository; showNamespace?: boolean }> = ({
  repository,
  showNamespace = false,
}) => (
  <Styled.RepositoryCard>
    <Stack gap={15} style={{ height: '100%', justifyContent: 'space-between' }}>
      <RepositoryHeader showNamespace={showNamespace} repository={repository} />
      <p>{repository.description}</p>
      <Row gap={20} justifyContent="space-between" className="repo-card-row">
        <div>
          <RepositoryDataTypesWithSize repository={repository} sizeInBytes={repository.size} />
        </div>
        <div>
          <Row gap={10} alignItems="center" justifyContent="flex-start">
            <RepositoryCategories repository={repository} />
            <Tag small variant="transparent" title="Total downloads">
              <Icon.Download />
              <span>{repository.metrics.clone_count}</span>
            </Tag>
            <Tag small variant="transparent" title="Total stars">
              <Icon.Star />
              <span>{repository.star_count}</span>
            </Tag>
          </Row>
        </div>
      </Row>
    </Stack>
  </Styled.RepositoryCard>
);

export default RepositoryCard;
