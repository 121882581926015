'use client';
import React from 'react';
import * as Icon from '@/ui/Icon';
import ReactPaginate from 'react-paginate';
import { useRouter, usePathname } from 'next/navigation';
import isMobile from 'ismobilejs';

import * as Styled from './styled';

import { IPagination } from '@/types';

const Pagination: React.FC<{
  onClick?: (pageNum: number) => void;
  pagination: IPagination;
}> = ({ pagination, onClick }) => {
  const pathname = usePathname();
  const router = useRouter();

  const mobileView = isMobile().phone;

  const onPageChange = (event: { selected: number }) => {
    const { selected: selectedIndex } = event;
    const pageNum = selectedIndex + 1;
    if (onClick) {
      onClick(pageNum);
    } else {
      router.push(`${pathname}?page=${pageNum}`, undefined);
      // const win: Window = window;
      // win.location = `${pathname}?page=${pageNum}`;
    }
  };

  return (
    <Styled.Pagination>
      {/* <div>{pathname}</div> */}
      <ReactPaginate
        breakLabel="..."
        nextLabel={<Icon.Right />}
        onPageChange={onPageChange}
        pageRangeDisplayed={mobileView ? 2 : 10}
        marginPagesDisplayed={mobileView ? 1 : 5}
        hrefBuilder={(page) => pathname + `?page=${page}`}
        pageCount={parseInt(pagination?.total_pages.toString()) || 1}
        forcePage={Math.ceil(pagination?.page_number || 1) - 1}
        previousLabel={<Icon.Left />}
        activeClassName="active"
      />
    </Styled.Pagination>
  );
};

export default Pagination;
